.homeMenuItems {
    @apply px-3 py-2 text-base !bg-white border-2 border-black;
}

.catButtons {
    @apply p-2 border my-2;
}

.longFormUserTable table {
    @apply 1xl:min-w-[60%] min-w-full m-auto;
}

.longFormUserTable table tr td {
}

html {
    scroll-behavior: smooth;
  }

.mainContent h2 {
    @apply text-tavPurple;
}

.testTags1 {
    @apply border border-black px-3 py-2 rounded-full uppercase hover:bg-tavPurple hover:text-white cursor-pointer;
}

.hamburger {
    @apply flex-col space-y-1;
}

.hamburger div {
    @apply bg-white h-0.5 w-5;
}


.eventDates {
    @apply py-5;
}


/* purgecss start ignore */


.eventCat-lunchtime-talk {
    @apply bg-[#33AF92] text-white;
}

.eventCat-dcp-workshop {
    @apply bg-[#9EAF33] text-white;
}

.eventCat-open-evening {
    @apply bg-[#2E2D2C] text-white;
}

.eventCat-conference {
    @apply bg-[#39B12F] text-white;
}

.catColour-focusTypes {
    @apply bg-green-300;
}

.catColour-projectTypes {
    @apply bg-red-300;
}

.catColour-sectors {
    @apply bg-blue-300;
}

.catColour-themes {
    @apply bg-yellow-300;
}

.contentsOpen {
    border-radius: 1rem !important;
    transition: border-radius 0.2s ease-in-out;
}

.ctContents {
    max-height: 0;
    max-width: 0;
    transition: max-height 0.4s cubic-bezier(0, 1, 0, 1), max-width 0.4s cubic-bezier(0, 1, 0, 1);
}

.contentsOpen .ctContents {
    max-height: 1000px;
    max-width: 1000px;
    transition: max-height 0.5s ease-in-out, max-width 0.5s ease-in-out;
}




/* purgecss end ignore */