@import "tailwindcss/base";

@import "./typography.css";
@import "tailwindcss/components";


@import "./layout.css";



@import "tailwindcss/utilities";